
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../axiosinstance";
//create cause

export const createCause = createAsyncThunk("createCause", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `category-service/api/Category/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getCause = createAsyncThunk("getCause", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `category-service/api/Category/v1/get?type=CAUSE`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateCause = createAsyncThunk("updateCause", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteCause = createAsyncThunk("deleteCause", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `category-service/api/Category/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});





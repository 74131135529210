import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import swal from "sweetalert";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { validationdata } from "./schemas";

// Add imports for Select and MenuItem
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import { MoonLoader } from "react-spinners";
import { makeApiRequest } from "./service/registration";
import { Checkbox, FormControlLabel } from "@mui/material";
import { red } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const initialValues = {
  // mpin: "",
  // confirm_mpin: "",
  firstName: "",
  lastname: "",
  email: "",
  // mobileNumber: "",
  mobileNumber: "",
  firmname: "",
  pincode: "",
};

const NewRegistration = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showFirmName, setShowFirmName] = useState(false);
  // const dispatch = useDispatch();
  // const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/pages/profile/profile-overview");
  //     // window.location.reload();
  //   }
  // }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      mobileNumber: location?.state?.mobileno,
      showFirmName,
    },
    validationSchema: validationdata,
    onSubmit: async (values, action) => {
      setIsLoading(true);

      try {
        const requestData = {
          title: title,
          emailid: values.email,
          name: values.lastname
            ? values.firstName + " " + values.lastname
            : values.firstName,
          id: null,
          rolename: "Admin",
          rolecode: "JA001",
          mobileno: values.mobileNumber,
          // password: values.mpin,
          firmname: values.firmname,
          pincode: values.pincode,
          resourcename: "Jaina India",
          recstatus: "APPROVED",
          resourcecode: "JAINA",
          isactive: "ACTIVE",
          status: "ACTIVE",
          username: "",
          createdby: values.firstName,
        };
        const responseData = await makeApiRequest(requestData);

        console.log("API Response:", responseData?.data?.dto?.profileid);
        setIsLoading(false);
        // dispatch({ type: "LOGIN_FULFILLED", payload: responseData });
        navigate("/app/MobileOtpverification/mobile-otp", {
          state: { responseData },
        });

        action.resetForm();
      } catch (error) {
        swal("Oops!", error.response.data.aceErrors[0].errorMessage, "warning");
        setIsLoading(false);
      }
    },
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    formik;

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setShowFirmName(event.target.checked);
    formik.setFieldValue("showFirmName", event.target.checked); // Dynamically update Formik state when checkbox changes
  };

  return (
    <CoverLayout
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh",
        maxWidth: "100vh",
        margin: 0,
      }}
      image={bgImage}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: "450px",
          margin: "0 auto",
          marginBottom: "20px",
        }}
      >
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
            Sign up
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {/* Enter your details and MPIN to Sign In */}
            Enter your details to Sign In
          </MDTypography>
        </MDBox>

        <form onSubmit={handleSubmit}>
          <MDBox pb={3} px={3}>
            <Grid container spacing={3} mt={0}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: red[800],
                        "&.Mui-checked": {
                          color: red[600],
                        },
                      }}
                      checked={showFirmName}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="For Dealer"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="title">Title</InputLabel>
                  <Select
                    native
                    name="title"
                    id="title"
                    label="Title"
                    value={title}
                    onChange={handleTitleChange}
                    inputProps={{
                      name: "title",
                      id: "title",
                    }}
                  >
                    <option value=""></option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={8}>
                <MDInput
                  fullWidth
                  name="firstName"
                  id="firstName"
                  type="text"
                  label="First Name"
                  autoComplete="off"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.firstName && touched.firstName && (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.firstName}
                  </p>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="lastname"
                id="lastname"
                type="text"
                label="Last Name"
                autoComplete="off"
                value={values.lastname}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.lastname && touched.lastname && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.lastname}
                </p>
              )}
            </Grid>

            {showFirmName && (
              <Grid item xs={12} mt={1.5}>
                <MDInput
                  fullWidth
                  name="firmname"
                  id="firmname"
                  type="text"
                  label="Firm Name"
                  autoComplete="off"
                  value={values.firmname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.firmname && touched.firmname && (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.firmname}
                  </p>
                )}
              </Grid>
            )}

            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="mobileNumber"
                id="mobileNumber"
                // type="number"
                type="text"
                label="Mobile Number"
                autoComplete="off"
                value={values.mobileNumber}
                disabled
                onBlur={handleBlur}
                // onChange={handleChange}
              />
              {errors.mobileNumber && touched.mobileNumber && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.mobileNumber}
                </p>
              )}
            </Grid>
            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="email"
                id="email"
                type="text"
                label="Email"
                autoComplete="off"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.email && touched.email && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.email}
                </p>
              )}
            </Grid>

            {/* <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="mpin"
                id="mpin"
                type="number"
                label="MPIN"
                autoComplete="off"
                value={values.mpin}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.mpin && touched.mpin && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.mpin}
                </p>
              )}
            </Grid>

            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="confirm_mpin"
                id="confirm_mpin"
                type="number"
                autoComplete="off"
                label="Confirm MPIN"
                value={values.confirm_mpin}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              {errors.confirm_mpin && touched.confirm_mpin && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.confirm_mpin}
                </p>
              )}
            </Grid> */}

            <Grid item xs={12} mt={1.5}>
              <MDInput
                fullWidth
                name="pincode"
                id="pincode"
                type="text"
                label="Pincode"
                autoComplete="off"
                value={values.pincode}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.pincode && touched.pincode && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.pincode}
                </p>
              )}
            </Grid>

            <Grid container spacing={3} mt={0}>
              <Grid item xs={12} sm={3}>
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  disabled={isLoading}
                  sx={{ cursor: "pointer", position: "absolute", left: "38%" }}
                >
                  {isLoading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Submit"
                  )}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </form>
        <MDBox mb={1} mt={4} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/authentication/sign-in/basic"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign In
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default NewRegistration;

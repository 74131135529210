import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Statusedit from "./Statusedit";
import { FaRegEdit } from "react-icons/fa";
import { deleteStatus, getStatus } from "../service/Status";

function StatusList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, loading } = useSelector((state) => state.status);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  function editStatus(item) {
    setopenpopup(true);
    setSelectedStatus(item);
  }

  function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteStatus(item))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getStatus());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataTable
        canSearch={true}
        table={{
          columns: [
            { Header: "name", accessor: "name" },
            { Header: "status", accessor: "recstatus" },
            { Header: "sub status", accessor: "subrecstatus" },
            {
              Header: "Created Date",
              accessor: "createddate",
              Cell: ({ value }) => {
                const formattedDate = new Date(value).toDateString();
                return formattedDate;
              },
            },

            {
              Header: "Action",
              accessor: "action",

              Cell: ({ row }) => (
                <div>
                  <Link to={{}}>
                    <FaRegEdit
                      style={iconStyleedit}
                      onClick={() => {
                        editStatus(row.original);
                      }}
                    />
                  </Link>

                  <Link to={{}}>
                    <MdDelete
                      style={iconStyle}
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    />
                  </Link>
                </div>
              ),
            },
          ],
          rows: status || [],
        }}
      />
      <Statusedit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Status Edit"}
        selectedStatus={selectedStatus}
      ></Statusedit>
    </DashboardLayout>
  );
}

export default StatusList;

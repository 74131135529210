//     phonenumber: {
//       name: "phonenumber",
//       label: "Phonenumber",
//       type: "number",
//       errorMsg: "number is required.",
//     },

const form = {
  formId: "new-user-form",
  formField: {
    serialNumber: {
      name: "serialNumber",
      label: "Serial Number",
      type: "text",
      errorMsg: "Serial Number is required.",
    },

    callsource: {
      name: "callsource",
      label: "Callsource",
      type: "select", // Set the type appropriately
      errorMsg: "Callsource is required.",
    },
    callnature: {
      name: "callnature",
      label: "Call nature",
      type: "select", // Set the type appropriately
      errorMsg: "Callnature is required.",
    },
    callby: {
      name: "callby",
      label: "Call By",
      type: "select", // Set the type appropriately
      errorMsg: "Call By is required.",
    },
    purchasesource: {
      name: "purchasesource",
      label: "Purchasesource",
      type: "select", // Set the type appropriately
      errorMsg: "Purchase Source is required.",
    },
    purchasesubsource: {
      name: "purchasesubsource",
      label: "Purchasesubsource",
      type: "select", // Set the type appropriately
      errorMsg: "Purchase sub source is required.",
    },
    callreason: {
      name: "callreason",
      label: "Call reason",
      type: "select", // Set the type appropriately
      errorMsg: "Call reason  is required.",
    },
    complaintpriority: {
      name: "complaintpriority",
      label: "Complaint Priority",
      type: "select", // Set the type appropriately
      errorMsg: "Complaint priority  is required.",
    },
    //personal Detail
    title: {
      name: "title",
      label: "Title",
      type: "text",
      errorMsg: "Title is required.",
    },
    lastcomplaint: {
      name: "lastcomplaint",
      label: "Last Complaint",
      type: "text",
      errorMsg: "Lastcomplaint is required.",
    },

    dealer: {
      name: "dealer",
      label: "Dealer",
      type: "select", // Set the type appropriately
      errorMsg: "Dealer  is required.",
    },
    calltype: {
      name: "calltype",
      label: "Calltype",
      type: "select", // Set the type appropriately
      errorMsg: "Calltype  is required.",
    },
    firstname: {
      name: "firstname",
      label: "First Name",
      type: "text",
      errorMsg: "Firstname is required.",
    },
    specialinstruction: {
      name: "specialinstruction",
      label: "Special instruction",
      type: "text",
      errorMsg: "Special instruction is required.",
    },
    issues: {
      name: "issues",
      label: "Issues",
      type: "text",
      errorMsg: "Issue is required.",
    },
    photos: {
      name: "photos",
      label: "Add Photos",
      type: "text",
      errorMsg: "Photos is required.",
    },
    date: {
      name: "date",
      label: "Date",
      type: "Date",
      errorMsg: "Date is required.",
    },
    slot: {
      name: "slot",
      label: "Time Slot",
      type: "select",
      errorMsg: "Time slot is required.",
    },

    ampm: {
      name: "ampm",
      label: "AM/PM",
      type: "select",
      errorMsg: "AM/PM is required.",
    },
    fullreport: {
      name: "fullreport",
      label: "Fault Report",
      type: "select",
      errorMsg: "Fault report is required.",
    },
    wallmount: {
      name: "wallmount",
      label: "Wallmount",
      type: "select",
      errorMsg: "Wallmount is required.",
    },
    timeofresolution: {
      name: "timeofresolution",
      label: "Estimate time of resolution (in hrs)",
      type: "number",
      errorMsg: "Time of resolution is required.",
    },

    closedreason: {
      name: "closedreason",
      label: "Closed Reason",
      type: "select",
      errorMsg: "Closedreason is required.",
    },
    lastname: {
      name: "lastname",
      label: "Last Name",
      type: "text",
      errorMsg: "Lastname is required.",
    },
    //purchase detail
    product: {
      name: "product",
      label: "Product",
      type: "select",
      errorMsg: "Product is required.",
    },
    invoice: {
      name: "invoice",
      label: "Invoice Number",
      type: "text",
      errorMsg: "Invoice is required.",
    },

    //contact Detail
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    mobilenumber: {
      name: "mobilenumber",
      label: "Mobile number",
      type: "number",
      errorMsg: "Mobile number is required.",
    },
    alternatephonenumber: {
      name: "alternatephonenumber",
      label: "Alternate  Mobile Number",
      type: "number",
      errorMsg: "Alternate phonenumber is required.",
    },
    address: {
      name: "address",
      label: "Address",
      type: "radio",
      errorMsg: "Address is required.",
    },
  },
};

export default form;

import { createSlice } from "@reduxjs/toolkit";
import { createSymptom, deleteSymptom, getSymptom, updateSymptom } from "../service/Symptom";





const initialState = {
  symptom: [],
  loading: false,
  error: null,
};

export const symptomDetail = createSlice({
  name: "symptomDetail",
  initialState,
  extraReducers: {
    [createSymptom.pending]: (state) => {
      state.loading = true;
    },
    [createSymptom.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.symptom.push(action.payload.data.dto);
    },
    [createSymptom.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getSymptom.pending]: (state) => {
      state.loading = true;
    },
    [getSymptom.fulfilled]: (state, action) => {
      state.loading = false;
      state.symptom = action.payload.data.dtoList;
    },
    [getSymptom.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteSymptom.pending]: (state) => {
      state.loading = true;
    },
    [deleteSymptom.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.symptom = state.symptom.filter((symptom) => symptom.id !== id);
      }
    },
    [deleteSymptom.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateSymptom.pending]: (state) => {
      state.loading = true;
    },
    [updateSymptom.fulfilled]: (state, action) => {
      state.loading = false;
    
      const index = state.symptom.findIndex(symptom => symptom.id === action.payload.data.dto.id)
      state.symptom[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateSymptom.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default symptomDetail.reducer;

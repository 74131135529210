import * as Yup from "yup";
import checkout from "./form";
const {
  formField:{ name, recstatus, subrecstatus },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [recstatus.name]: Yup.string().required(recstatus.errorMsg),
    [subrecstatus.name]: Yup.string().required(subrecstatus.errorMsg),
  }),
];

export default validations;

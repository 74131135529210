
import { combineReducers, legacy_createStore as createStore, applyMiddleware } from
    "redux"
import thunk from "redux-thunk"
import AuthReducer from "./Reducers/AuthReducer"
import { composeWithDevTools } from 'redux-devtools-extension'
import product from 'app/productMaster/reducer/productDetailSlice';
import category from 'app/productMaster/reducer/categoryDetailSlice';
import brand from 'app/createBrand/reducer/brandDetailSlice';
import address from 'app/productRegistration/components/addressDetail/reducer/addressCrudSlice'
import productregistration from  "app/productRegistration/reducer/productRegistrationSlice"
import delar from "app/createdelar/reducer/delarDetailSlice"
import modal from "app/modalMaster/reducer/modalDetailSlice";
import symptom from "app/symptomMaster/reducer/symptomDetailSlice";
import cause from "app/causeMaster/reducer/causeDetailSlice";
import analysis from "app/analysisMaster/reducer/analysisDetailSlice";
import status from "app/statusMaster/reducer/statusDetailSlice";
import job from "app/serviceRequest/reducer/jobserviceslice";
import serail from "app/productRegistration/serialreducer/serialSlice"
import pincode from "app/productRegistration/components/addressDetail/reducer/pincodeslice"
import locality from "app/productRegistration/components/addressDetail/reducer/localityslice"
import states from "app/productRegistration/components/addressDetail/reducer/stateslice"
import cities from "app/productRegistration/components/addressDetail/reducer/cityslice"
import village from "app/productRegistration/components/addressDetail/reducer/villageslice"

const store = createStore(
    combineReducers({AuthReducer,product,category,brand,modal,symptom,cause,status,analysis,address,productregistration,delar,job,serail,pincode,locality,states,cities,village}),
    composeWithDevTools(applyMiddleware(thunk))
)

export default store


import { createSlice } from "@reduxjs/toolkit";
import { createAnalysis, deleteAnalysis, getAnalysis, updateAnalysis } from "../service/Analysis";





const initialState = {
  analysis: [],
  loading: false,
  error: null,
};

export const analysisDetail = createSlice({
  name: "analysisDetail",
  initialState,
  extraReducers: {
    [createAnalysis.pending]: (state) => {
      state.loading = true;
    },
    [createAnalysis.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.analysis.push(action.payload.data.dto);
    },
    [createAnalysis.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getAnalysis.pending]: (state) => {
      state.loading = true;
    },
    [getAnalysis.fulfilled]: (state, action) => {
      state.loading = false;
      state.analysis = action.payload.data.dtoList;
    },
    [getAnalysis.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteAnalysis.pending]: (state) => {
      state.loading = true;
    },
    [deleteAnalysis.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.analysis = state.analysis.filter((analysis) => analysis.id !== id);
      }
    },
    [deleteAnalysis.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateAnalysis.pending]: (state) => {
      state.loading = true;
    },
    [updateAnalysis.fulfilled]: (state, action) => {
      state.loading = false;
    
      const index = state.analysis.findIndex(analysis => analysis.id === action.payload.data.dto.id)
      state.analysis[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateAnalysis.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default analysisDetail.reducer;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getProduct, updateProduct } from "../service/Product";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

const Productedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedProduct, openpopup, setopenpopup } = props;
  const { brand, loading } = useSelector((state) => state.brand);
  const { category } = useSelector((state) => state.category);
  const [branddefaultvalue, setbranddefaultvalue] = useState("");
  const [categorydefaultvalue, setcategorydefaultvalue] = useState("");

  // Validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required("Product Name is required."),
    brand: Yup.string().required("Brand is required."),
    category: Yup.string().required("Category is required."),
    description: Yup.string(),
  });

  console.log("selectedProduct", selectedProduct);

  async function getbranddefaultvalue() {
    const result = await brand.filter(
      (brand) =>
        brand.hierarchicalcode === selectedProduct?.parenthierarchicalcode
    );
    console.log("rerererr", result);
    setbranddefaultvalue(result[0]?.hierarchicalcode);
  }

  async function getcategorydefaultvalue() {
    const result = await category?.filter(
      (category) => category.name === selectedProduct?.category
    );
    console.log("recacacrererr", result);
    setcategorydefaultvalue(result[0]?.name);
  }

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedProduct?.name || "",
      description: selectedProduct?.desc || "",
      brand: branddefaultvalue || "",
      category: categorydefaultvalue || "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("submitvalue", values);
      // Create a copy of selectedProduct with updated values
      const updatedProduct = {
        ...selectedProduct,
        desc: values.description.trim(),
        name: values.name.trim(),
        recstatus: "OPEN",
        resourcecode: "JAINA",
        category: values.category,
        status: "Active",
        syncstatus: "SYNCED",
        type: "PRODUCT",
        parenthierarchicalcode: values.brand,
        hierarchicalcode: values.brand + "-" + selectedProduct?.code,

        // Update parentdepcode with brand
      };
      console.log("payload", updatedProduct);

      // Dispatch the action to update the product in the Redux store
      dispatch(updateProduct(updatedProduct));

      // Close the dialog
      // dispatch(getProduct());
      setopenpopup(false);
    },
  });

  useEffect(() => {
    getbranddefaultvalue();
    getcategorydefaultvalue();
    // Update the form values when selectedProduct changes
    formik.setValues({
      name: selectedProduct?.name || "",
      description: selectedProduct?.desc || "",
      category: categorydefaultvalue || "",
      brand: branddefaultvalue || "",
    });
  }, [selectedProduct, branddefaultvalue, categorydefaultvalue]);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent style={{ maxwidth: 550, height: 230 }}>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>
                Product Name{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="brand"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "8px",
                }}
              >
                Select Brand{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="brand"
                id="brand"
                value={formik.values.brand}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.brand && Boolean(formik.errors.brand)}
              >
                {brand?.map((option) => (
                  <option key={option.id} value={option.hierarchicalcode}>
                    {option.name}
                  </option>
                ))}
              </Select>
              {formik.touched.brand && formik.errors.brand && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.brand}
                </p>
              )}
            </FormControl>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Product Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="category"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "8px",
                }}
              >
                Select Category{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="category"
                id="category"
                value={formik.values.category}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
              >
                {category?.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Select>
              {formik.touched.category && formik.errors.category && (
                <p
                  style={{
                    fontWeight: "400",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.category}
                </p>
              )}
            </FormControl>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Productedit;

import React, { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import PreviewIcon from "@mui/icons-material/Preview";
//edit icon

import { getJob } from "./server/jobservice";
import Viewpopup from "./components/view popup";
import { Link } from "react-router-dom";
import ServiceRequest from "./ServiceRequest";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Card, Dialog, DialogContent } from "@mui/material";

function ServerList() {
  const dispatch = useDispatch();
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth"))?.profileid
  );
  const [open, setOpen] = useState(false);
  const { job, loading } = useSelector((state) => state.job);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedcomplaint, setSelectecomplaint] = useState(null);
  // const [loading, setLoading] = useState(true);

  const viewcomplaintDetail = (item) => {
    console.log("item", item);
    setopenpopup(true);
    setSelectecomplaint(item);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    dispatch(getJob(profileId));
  }, []);

  if (loading) {
    // Render a centered circular loader
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <MDTypography variant="h5" fontWeight="medium">
            Compalint List
          </MDTypography>
          <MDButton color="info" onClick={() => setOpen(true)}>
            ADD
          </MDButton>
        </div>
        <DataTable
          canSearch={true}
          table={{
            columns: [
              { Header: "Compalint Number", accessor: "id" },

              {
                Header: "product name",
                accessor: "product",
                Cell: ({ row }) => {
                  //  const product = row?.original?.productregister?.product?.brand?.name+" "+row?.original?.productregister?.product?.product?.name+" "+row?.original?.productregister?.product?.model?.name;
                  const product =
                    row?.original?.productregister?.product?.brand?.name +
                    " " +
                    row?.original?.productregister?.product?.product?.name +
                    " (" +
                    row?.original?.productregister?.product?.model?.desc +
                    ") (" +
                    row?.original?.productregister?.product?.model?.modelno +
                    ")";
                  return product;
                },
              },
              {
                Header: "full name",
                accessor: "firstname",
                Cell: ({ row }) => {
                  const fullName =
                    row?.original?.title +
                    " " +
                    row?.original?.firstname +
                    " " +
                    row?.original?.lastname;
                  return fullName;
                },
              },
              {
                Header: "serialnumber",
                accessor: "productregister.product.serialnumber",
              },
              {
                Header: "Complaint Date",
                accessor: "createddate",
                Cell: ({ value }) => {
                  const formattedDate = new Date(value).toDateString();
                  return formattedDate;
                },
              },

              { Header: "Status", accessor: "recstatus" },
              { Header: "complaint priority", accessor: "complaintpriority" },

              {
                Header: "Action",
                accessor: "action",

                Cell: ({ row }) => (
                  <div>
                    <Link to={{}}>
                      <PreviewIcon
                        fontSize="medium"
                        onClick={() => {
                          viewcomplaintDetail(row.original);
                        }}
                      />
                    </Link>
                  </div>
                ),
              },
            ],
            rows: job || [],
          }}
        />
      </Card>
      <Viewpopup
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Complaint Detail"}
        selectedcomplaint={selectedcomplaint}
      />
      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogContent>
            <ServiceRequest handleClose={handleClose} />
          </DialogContent>
        </Dialog>
      )}
    </DashboardLayout>
  );
}

export default ServerList;

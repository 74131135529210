import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Grid from "@mui/material/Grid";
import swal from "sweetalert";
import * as Yup from "yup";
// Images
import bgImage from "assets/images/bg-pricing.jpg";

import { useNavigate, useLocation } from "react-router-dom";
import { confirmOtp, createOtp } from "./service/OtpService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logIn } from "redux/Actions/authActions";

const initialValues = {
  otp: "",
};

const validationSchema = Yup.object({
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{6}$/, "OTP must be a 6-digit number"),
});

const MobileOtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state?.responseData?.data.dto);
  // console.log("1222",location.state.responseData.data.dto)
  const [timer, setTimer] = useState(300); // 5 minutes in seconds
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/pages/profile/profile-overview");
    }
  }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      // setIsLoading(true);
      try {
        // Call the API function with form values

        // const responseData = await confirmOtp(data?.mobileno, values?.otp);
        const confirmotpdata = {
          otp:values?.otp,
          mobileNumber:data?.mobileno
        }
        const responseData = await dispatch(logIn(confirmotpdata));

        // Handle the response data as needed
        console.log("API Response:", responseData);
        swal("Verified!", "Your Otp Has Been Verified!", "success");
        // navigate("/authentication/sign-in/basic");
        // Reset the form after successful submission
      } catch (error) {
        // Handle any API errors or other errors
        swal("Oops!", error.response.data.aceErrors[0].errorMessage, "warning");
      }
      // finally {
      //   setIsLoading(false); // Reset isLoading to false when submission is complete
      // }
    },
  });

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      // Timer expired, you can handle this event as needed
    }
  }, [timer]);

  async function handleResendOtp() {
    // Implement logic to resend OTP here
    // You can reset the timer if needed
    setTimer(300); // Reset the timer to 5 minutes
    try {
      // Call the API function with form values
      // const requestData = {
      // createdby: "WEBUI",
      //     // "otp": "string",
      //     otptype: "SMS",
      //     recstatus: "OPEN",
      //     resourcecode: "JAINA",
      //     resourcename: "Jaina India",
      //     status: "ACTIVE",

      //     typevalue: "CREATE",
      //     userid: data.profileid

      // };
      const requestData = {
        otptype: "SMS",
        typevalue: "DIRECT",
        resourcename: "Jaina India",
        resourcecode: "JAINA",
        status: "ACTIVE",
        userid: data.mobileno,
      };
      const responseData = await createOtp(requestData);

      // Handle the response data as needed
      console.log("API Response:", responseData);

      // Reset the form after successful submission
      // action.resetForm();
    } catch (error) {
      // Handle any API errors or other errors
      console.error("Form submission error:", error);
    }
    // finally {
    //   setIsLoading(false); // Reset isLoading to false when submission is complete
    // }
  }

  useEffect(() => {
    handleResendOtp();
  }, []);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-1}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
            OTP Page
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter the OTP sent to your email/phone:
          </MDTypography>
        </MDBox>

        <form onSubmit={formik.handleSubmit}>
          <MDBox pb={3} px={3} mt={2}>
            <MDInput
              fullWidth
              name="otp"
              id="otp"
              type="text"
              label="Enter OTP"
              autoComplete="off"
              value={formik.values.otp}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
            />

            {formik.touched.otp && formik.errors.otp && (
              <p style={{ fontWeight: "400", color: "red", fontSize: "12px" }}>
                {formik.errors.otp}
              </p>
            )}
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="body2" color="textSecondary">
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {" "}
                  Time remaining:
                </span>{" "}
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {Math.floor(timer / 60)}
                </span>
                :
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {(timer % 60).toString().padStart(2, "0")}
                </span>
              </MDTypography>

              <MDTypography variant="button" color="text">
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "blue", // Customize the color as needed
                    fontWeight: "bold",
                  }}
                  onClick={handleResendOtp} // Replace "yourFunction" with the actual function you want to call
                >
                  Resend Otp
                </span>
              </MDTypography>
            </MDBox>

            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={8}>
                {/* Any additional components */}
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  Submit
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </form>
      </Card>
    </CoverLayout>
  );
};

export default MobileOtpVerification;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "status-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    recstatus: {
      name: "recstatus",
      label: "Status",
      type: "text",
      errorMsg: "Status is required.",
    },
    subrecstatus: {
      name: "subrecstatus",
      label: "Sub Status",
      type: "text",
      errorMsg: "Sub Status is required.",
    },
  },
};

export default form;

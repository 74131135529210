import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { FaRegEdit } from "react-icons/fa";
import { deleteSymptom, getSymptom } from "./service/Symptom";
import { getProduct } from "app/productMaster/service/Product";
import { getBrand } from "app/createBrand/service/Brand";
import SymptomEdit from "./Symptomedit";

function SymptomList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { symptom, loading } = useSelector((state) => state.symptom);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState(null);
  function editBrand(item) {
    setopenpopup(true);
    setSelectedSymptom(item);
  }

  function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteSymptom(item))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getSymptom());
    dispatch(getProduct());
    dispatch(getBrand());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataTable
        canSearch={true}
        table={{
          columns: [
            { Header: "product name", accessor: "mappingcode" },
            { Header: "symptom", accessor: "name" },
            {
              Header: "Created Date",
              accessor: "createddate",
              Cell: ({ value }) => {
                const formattedDate = new Date(value).toDateString();
                return formattedDate;
              },
            },
            {
              Header: "Action",
              accessor: "action",

              Cell: ({ row }) => (
                <div>
                  <Link to={{}}>
                    <FaRegEdit
                      style={iconStyleedit}
                      onClick={() => {
                        editBrand(row.original);
                      }}
                    />
                  </Link>

                  <Link to={{}}>
                    <MdDelete
                      style={iconStyle}
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    />
                  </Link>
                </div>
              ),
            },
          ],
          rows: symptom || [],
        }}
      />
      <SymptomEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        selectedSymptom={selectedSymptom}
        title={"Symptom Edit"}
      />
    </DashboardLayout>
  );
}

export default SymptomList;

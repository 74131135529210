import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { createAnalysis } from "./service/Analysis";
import { getCause } from "app/causeMaster/service/Cause";

function AnalysisMaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cause } = useSelector((state) => state.cause);
  const { error, loading } = useSelector((state) => state.cause);
  const validationSchema = Yup.object().shape({
    cause: Yup.string().required("Cause is required."),
    analysisname: Yup.string().required("Analysis is required."),
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getCause());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={15} height="60vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{
                cause: "",
                analysisname: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, action) => {
                setIsLoading(true);
                const requestData = {
                  name: values.analysisname,
                  mappingcode: values.cause,
                  recstatus: "OPEN",
                  resourcecode: "JAINA",
                  status: "ACTIVE",
                  syncstatus: "SYNCED",
                  type: "ANALYSIS",
                  classname: "Category",
                };
                const response = await dispatch(createAnalysis(requestData));

                if (!response.error && !loading) {
                  action.resetForm();
                  navigate("/app/analysislist/analysis-list");
                } else {
                  action.resetForm();
                  swal({
                    icon: "error",
                    title: "Error",
                    text: error.message,
                  });
                }
                setIsLoading(false);
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Analysis Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="cause"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Select Cause{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="cause"
                              id="cause"
                              value={values.cause}
                              onChange={(e) => {
                                const selectedSymptom = e.target.value;
                                setFieldValue("cause", selectedSymptom);
                              }}
                              error={touched.cause && Boolean(errors.cause)}
                            >
                              <option value=""></option>
                              {cause?.map((option) => (
                                <option key={option.id} value={option.code}>
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="cause"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                Analysis
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              name="analysisname"
                              id="analysisname"
                              value={values.analysisname}
                              onChange={(e) => {
                                const analysisnameValue = e.target.value;
                                setFieldValue("analysisname", analysisnameValue);
                              }}
                              variant="standard"
                              error={
                                touched.analysisname && Boolean(errors.analysisname)
                              }
                              helperText={
                                touched.analysisname && errors.analysisname ? (
                                  <span style={{ fontWeight: "400" }}>
                                    {errors.analysisname}
                                  </span>
                                ) : null
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton type="submit" variant="gradient" color="dark">
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default AnalysisMaster;

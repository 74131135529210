import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { updateCause } from "./service/Cause";

const validationSchema = Yup.object().shape({
  symptom: Yup.string().required("Symptom is required."),
  causename: Yup.string().required("Cause is required."),
});

const CauseEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedCause, openpopup, setopenpopup } = props;
  const { symptom } = useSelector((state) => state.symptom);

  const [defaultValues, setDefaultValues] = useState({
    symptom: "",
    causename: "",
  });

  const handleClose = () => {
    setopenpopup(false);
  };

  useEffect(() => {
    async function getcausedefaultvalue() {
      if (selectedCause) {
        setDefaultValues({
          symptom: selectedCause?.mappingcode || "",
          causename: selectedCause?.name || "",
        });
      }
    }
    getcausedefaultvalue();
  }, [selectedCause]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, action) => {
          const requestData = {
            ...selectedCause,
            name: values.causename,
            mappingcode: values.symptom
              ? values.symptom
              : selectedSymptom?.mappingcode,
          };
          dispatch(updateCause(requestData));
          handleClose();
          action.resetForm();
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <DialogContent style={{ maxwidth: 550 }}>
              <MDBox p={3}>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="symptom"
                          style={{
                            marginLeft: "-14px",
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Select Symptom{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="symptom"
                          id="symptom"
                          value={values.symptom}
                          onChange={(e) => {
                            const selectedBrand = e.target.value;
                            setFieldValue("symptom", selectedBrand);
                          }}
                          error={touched.symptom && Boolean(errors.symptom)}
                        >
                          <option value=""></option>
                          {symptom?.map((option) => (
                            <option key={option.id} value={option.code}>
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="symptom"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label=<span>
                            Cause
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="causename"
                          id="causename"
                          value={values.causename}
                          onChange={(e) => {
                            const causenameValue = e.target.value;
                            setFieldValue("causename", causenameValue);
                          }}
                          variant="standard"
                          error={touched.causename && Boolean(errors.causename)}
                          helperText={
                            touched.causename && errors.causename ? (
                              <span style={{ fontWeight: "400" }}>
                                {errors.causename}
                              </span>
                            ) : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CauseEdit;

import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";

function StatusInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { name, recstatus, subrecstatus } = formField;
  var {
    name: nameV,
    recstatus: recstatusV,
    subrecstatus: subrecstatusV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Status Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label=<span>
                  {name.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={recstatus.type}
                label=<span>
                  {recstatus.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={recstatus.name}
                value={recstatusV}
                placeholder={recstatus.placeholder}
                error={errors.recstatus && touched.recstatus}
                success={recstatusV.length > 0 && !errors.recstatus}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={subrecstatus.type}
                label=<span>
                  {subrecstatus.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={subrecstatus.name}
                value={subrecstatusV}
                placeholder={subrecstatus.placeholder}
                error={errors.subrecstatus && touched.subrecstatus}
                success={subrecstatusV.length > 0 && !errors.subrecstatus}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for StatusInfo
StatusInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default StatusInfo;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import Addresscard from "./addresspopup/addresscard";
import { CircularProgress, FormHelperText } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { getAddress } from "./service/Address";
import Addresspopup from "app/productRegistration/components/addressDetail/addresspopup";
import MDButton from "components/MDButton";
import { deleteAddress } from "app/productRegistration/components/addressDetail/service/Address";
import Addresscard from "app/productRegistration/components/addressDetail/addresspopup/addresscard";

function AddressDetail({ formData }) {
  const { formField, values, errors, touched } = formData;
  const dispatch = useDispatch();

  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth")).profileid
  );
  const [openpopup, setopenpopup] = useState(false);
  const [editaddress, setEditaddress] = useState("");

  const { address, loading } = useSelector((state) => state.address);

  console.log("address", address);

  const [selectedaddress, setSelectedAddress] = useState(values.index || 0);

  const onSelectAddress = (index) => {
    setSelectedAddress(index);
    // Clear the error associated with the address field
    // You can set the address value in the form here
    const selectedValue = address[index];
    console.log("selected", selectedValue);
    values.address = selectedValue;
  };

  const addaddress = () => {
    setopenpopup(true);
  };

  // const radioStyle = {
  //   marginRight: "8px",
  // };
  const radioStyle = {
    marginRight: "8px",
    position: "relative",
  };

  const radioInputStyle = {
    // Increase the size of the radio button
    transform: "scale(1.5)", // Adjust the scale factor as needed
    marginRight: "5px", // Adjust spacing if necessary
    marginBottom: window.innerWidth <= 700 ? "-25px" : "0px",
  };

  const radioLabelStyle = {
    display: "flex",
    alignItems: "center",
  };

  //delete button
  const handledelete = (item) => {
    dispatch(deleteAddress(item));
  };

  // Initialize values.address with the first address on component mount
  useEffect(() => {
    const selectedValue = address[selectedaddress];

    values.address = selectedValue;
    values.index = selectedaddress;
  }, [selectedaddress, values, address]);

  useEffect(() => {
    dispatch(getAddress(profileId));
  }, []);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Address Detail
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3} mb={5}>
          <Grid item xs={12} sm={6}>
            <MDButton color="info" onClick={() => addaddress()}>
              Add Address
            </MDButton>
          </Grid>

          {openpopup && (
            <Addresspopup
              openpopup={openpopup}
              setopenpopup={(isOpen) => {
                if (!isOpen) {
                  setEditaddress(""); // Reset editaddress when the dialog is closed
                }
                setopenpopup(isOpen);
              }}
              editaddress={editaddress}
            />
          )}
        </Grid>

        <div
          style={{
            overflow: "auto",
            maxHeight: "210px",
            paddingRight: "15px",
            paddingTop: window.innerWidth <= 700 ? "25px" : "30px",
          }}
        >
          <style>
            {`div::-webkit-scrollbar {
              width: 10px;
              background: #bbbbbb;
              border-radius: 10px; }
            div::-webkit-scrollbar-thumb {
              background: #000000; 
              border-radius: 10px; }`}
          </style>
          <Grid container spacing={3}>
            {loading ? (
              // Display a loading indicator while data is loading
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "200px",
                }}
              >
                {" "}
                <CircularProgress />
              </div>
            ) : (
              // Render the address cards once data is available
              address.map((obj, index) => (
                <Grid item xs={12} sm={6} mb={3} mt={5} key={index}>
                  <Addresscard
                    title={
                      <p
                        style={{
                          color: "#505050",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        {obj.country}
                      </p>
                    }
                    action={
                      <>
                        <label style={radioLabelStyle}>
                          <input
                            type="radio"
                            value={values.index}
                            checked={selectedaddress === index}
                            onChange={() => onSelectAddress(index)}
                            style={radioInputStyle}
                          />
                          <span style={radioStyle}>
                            {touched[address.name] && errors[address.name] && (
                              <FormHelperText error>
                                {errors[address.name]}
                              </FormHelperText>
                            )}
                          </span>
                        </label>
                      </>
                    }
                    description=<p
                      style={{
                        color: "#505050",
                        fontWeight: "bold",
                        marginBottom:
                          window.innerWidth <= 700 ? "-74px" : "-20px",
                      }}
                    >
                      {obj?.mainaddress +
                        ", " +
                        obj?.landmark +
                        ", " +
                        obj.pincode?.village?.name +
                        ", " +
                        obj.pincode?.city?.name +
                        ", " +
                        obj.pincode?.state?.name +
                        ", " +
                        obj.pincode?.pincode}
                    </p>
                    edit={
                      <></>
                      // <MDTypography
                      //   variant="body1"
                      //   color="info"
                      //   lineHeight={1}
                      //   sx={{ cursor: "pointer", mx: 3 }}
                      //   mt={-1.5}
                      //   mb={-2}
                      // >
                      //   <Icon
                      //     fontSize="medium"
                      //     color="inherit"
                      //     onClick={() => handleedit(obj)}
                      //   >
                      //     edit
                      //   </Icon>
                      // </MDTypography>
                    }
                    remove={
                      <MDTypography
                        variant="body1"
                        color="info"
                        lineHeight={1}
                        sx={{
                          cursor: "pointer",
                          mx: 3,
                          display: window.innerWidth <= 700 ? "flex" : "auto",
                          justifyContent:
                            window.innerWidth <= 700 ? "center" : "auto",
                        }}
                        mt={-1.5}
                        mb={-2}
                      >
                        <Icon
                          fontSize="medium"
                          color="error"
                          onClick={() => handledelete(obj)}
                        >
                          delete
                        </Icon>
                      </MDTypography>
                    }
                  />
                </Grid>
              ))
            )}
          </Grid>
        </div>
      </MDBox>
    </MDBox>
  );
}

AddressDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddressDetail;

import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import Numberpopup from "./number popup/Numberpopup";
import MDButton from "components/MDButton";

function ContactDetail({ formData }) {
  const { formField, values, errors, touched } = formData;
  const [openpopup, setopenpopup] = useState(false);

  // Inside the module where auth is defined, export it

  const { mobilenumber, email, alternatephonenumber } = formField;
  const {
    mobilenumber: mobilenumberV,
    email: emailV,
    alternatephonenumber: alternatephonenumberV,
  } = values;

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("mmu-auth"));
    if (authData) {
      const { mobileno = "", emailid = "" } = authData;
      values.mobilenumber = mobileno;
      values.email = emailid;
    }
  }, []);

  function childToparent(data) {
    values.mobilenumber = data;
  }

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Contact Detail
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
              <FormField
                type={mobilenumber.type}
                label={mobilenumber.label}
                name={mobilenumber.name}
                value={mobilenumberV}
                placeholder={mobilenumber.placeholder}
                error={errors.mobilenumber && touched.mobilenumber}
                success={mobilenumberV.length > 0 && !errors.mobilenumber}
                disabled="true"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div
              style={{
                position: window.innerWidth <= 700 ? "absolute" : "static",
                top: window.innerWidth <= 700 ? "30%" : "auto",
                left: window.innerWidth <= 700 ? "66%" : "auto",
                marginRight: window.innerWidth <= 700 ? "20px" : "auto",
              }}
            >
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={() => setopenpopup(true)}
              >
                change number
              </MDButton>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "-30px" : "auto" }}
            >
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "10px" : "auto" }}
            >
              <FormField
                type={alternatephonenumber.type}
                label={alternatephonenumber.label}
                name={alternatephonenumber.name}
                value={alternatephonenumberV}
                placeholder={alternatephonenumber.placeholder}
                error={
                  errors.alternatephonenumber && touched.alternatephonenumber
                }
                success={
                  alternatephonenumberV.length > 0 &&
                  !errors.alternatephonenumber
                }
              />
            </div>
          </Grid>
        </Grid>
      </MDBox>
      <Numberpopup
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Change Number"}
        childToparent={childToparent}
      />
    </MDBox>
  );
}

// typechecking props for ContactDetail
ContactDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ContactDetail;

import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { getBrand } from "app/createBrand/service/Brand";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "app/productMaster/service/Product";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";
import { createSymptom } from "./service/Symptom";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";

function SymptomMaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brand } = useSelector((state) => state.brand);
  const { product } = useSelector((state) => state.product);
  const [productOptions, setProductOptions] = useState([]);
  const { error, loading } = useSelector((state) => state.symptom);
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    brand: Yup.string().required("Brand is required."),
    product: Yup.string().required("Product is required."),
    symptomname: Yup.string().required("Symptom is required."),
  });

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getProduct());
  }, [dispatch]);

  const fetchProductOptions = async (selectedBrand) => {
    const result = product?.filter(
      (productItem) => productItem?.parenthierarchicalcode === selectedBrand
    );
    setProductOptions(result);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={15} height="60vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={{
                product: "",
                brand: "",
                symptomname: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, action) => {
                setIsLoading(true);
                const requestData = {
                  name: values.symptomname,
                  mappingcode: values.product,
                  recstatus: "OPEN",
                  resourcecode: "JAINA",
                  status: "ACTIVE",
                  syncstatus: "SYNCED",
                  type: "SYMPTOM",
                  classname: "Category"
                };
                const response = await dispatch(createSymptom(requestData));

                if (!response.error && !loading) {
                  action.resetForm();
                  navigate("/app/symptomlist/symptom-list");
                } else {
                  action.resetForm();
                  swal({
                    icon: "error",
                    title: "Error",
                    text: error.message,
                  });
                }
                setIsLoading(false);
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Symptom Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="brand"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Select Brand{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="brand"
                              id="brand"
                              value={values.brand}
                              onChange={(e) => {
                                const selectedBrand = e.target.value;
                                setFieldValue("brand", selectedBrand);
                                fetchProductOptions(selectedBrand);
                              }}
                              error={touched.brand && Boolean(errors.brand)}
                            >
                              <option value=""></option>
                              {brand?.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.hierarchicalcode}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="brand"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="product"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Select Product{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="product"
                              id="product"
                              value={values.product}
                              onChange={(e) => {
                                const selectedProduct = e.target.value;
                                setFieldValue("product", selectedProduct);
                              }}
                              error={touched.product && Boolean(errors.product)}
                            >
                              <option value=""></option>
                              {productOptions?.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.name}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="product"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                Symptom
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              name="symptomname"
                              id="symptomname"
                              value={values.symptomname}
                              onChange={(e) => {
                                const symptomnameValue = e.target.value;
                                setFieldValue("symptomname", symptomnameValue);
                              }}
                              variant="standard"
                              error={
                                touched.symptomname &&
                                Boolean(errors.symptomname)
                              }
                              helperText={
                                touched.symptomname && errors.symptomname ? (
                                  <span style={{ fontWeight: "400" }}>
                                    {errors.symptomname}
                                  </span>
                                ) : null
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton type="submit" variant="gradient" color="dark">
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default SymptomMaster;

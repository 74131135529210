import { createSlice } from "@reduxjs/toolkit";
import { createStatus, deleteStatus, getStatus, updateStatus } from "../service/Status";



const initialState = {
  status: [],
  loading: false,
  error: null,
};

export const statusDetail = createSlice({
  name: "statusDetail",
  initialState,
  extraReducers: {
    [createStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createStatus.fulfilled]: (state, action) => {
      console.log("state",state.status)
      state.loading = false;
      state.status.push(action.payload.data.dto);
    },
    [createStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.status = action.payload.data.dtoList;

    },
    [getStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteStatus.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.status = state.status.filter((status) => status.id !== id);
      }
    },
    [deleteStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateStatus.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.status.findIndex(status => status.id === action.payload.data.dto.id)
      state.status[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default statusDetail.reducer;

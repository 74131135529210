 import * as Yup from "yup";

// export const validationdata = Yup.object({
//   firstName: Yup.string().required("Please enter your firstName"),
//   mpin: Yup.string().min(6).max(6).required("Please enter your mpin"),
//   email: Yup.string()
//   .email("Please enter a valid email address")
//   .required("Please enter your email address"),
//   confirm_mpin: Yup.string()
//     .required()
//     .oneOf([Yup.ref('mpin'), null], "MPIN must match"),
// });




export const validationdata = Yup.object({
  firstName: Yup.string().required("First name is required."),
  // mpin: Yup.string().min(6).max(6).required("MPIN is required."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required."),
  // confirm_mpin: Yup.string()
  //   .required("Confirm MPIN is required.")
  //   .oneOf([Yup.ref("mpin"), null], "MPIN must match."),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number.")
    .required("Mobile number is required."),
  firmname: Yup.string().when("showFirmName", {
    is: true,
    then: Yup.string().required("Firm name is required."),
    otherwise: Yup.string().notRequired(),
  }),
  pincode: Yup.string().required("Pincode is required."),
});



import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { CircularProgress, Grid } from "@mui/material";

const Viewpopup = (props) => {
  const { title, selectedcomplaint, openpopup, setopenpopup } = props;

  const [isloading, setLoading] = useState(false);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}

      >
        {title}
      </DialogTitle>

      <DialogContent>
        <div style={{ textAlign: "center" }}>
          <img
            src="https://aliceblueonline.com/wp-content/uploads/2022/10/Form-Caricature.png"
            alt="photo"
            style={{ width: "80%", height: "300px" ,marginTop:'10px'}}
          />
        </div>

        {isloading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        ) : (
          <MDBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Call By:{" "}
                </span>{" "}
                {selectedcomplaint?.callby}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Call Source:{" "}
                </span>{" "}
                {selectedcomplaint?.callsource}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Call Nature:{" "}
                </span>{" "}
                {selectedcomplaint?.callnature}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Purchase Source:{" "}
                </span>{" "}
                {selectedcomplaint?.purchasesource}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Selected Product:{" "}
                </span>{" "}
                {selectedcomplaint?.productregister?.product?.product?.name}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Complaint Priority:{" "}
                </span>{" "}
                {selectedcomplaint?.complaintpriority}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Invoice No:{" "}
                </span>{" "}
                {selectedcomplaint?.invoiceno}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Last Complaint No:{" "}
                </span>{" "}
                {selectedcomplaint?.lastcomplaintno}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Name:{" "}
                </span>{" "}
                {selectedcomplaint?.lastname
                  ? selectedcomplaint?.firstname +
                    " " +
                    selectedcomplaint?.lastname
                  : selectedcomplaint?.firstname}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Email:{" "}
                </span>{" "}
                {selectedcomplaint?.email}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Mobile No:{" "}
                </span>{" "}
                {selectedcomplaint?.phonenumber}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Closed Reason:{" "}
                </span>{" "}
                {selectedcomplaint?.closedreason}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Dealer:{" "}
                </span>{" "}
                {selectedcomplaint?.dealer?.name}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Call Type:{" "}
                </span>{" "}
                {selectedcomplaint?.calltype}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Wall Mount:{" "}
                </span>{" "}
                {selectedcomplaint?.wallmount}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Fault Report:{" "}
                </span>{" "}
                {selectedcomplaint?.faultreported}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Special Instruction:{" "}
                </span>{" "}
                {selectedcomplaint?.specialinstruction}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Cust. Avialable Date:{" "}
                </span>{" "}
                {selectedcomplaint?.customeravialabledate}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Slot:{" "}
                </span>{" "}
                {selectedcomplaint?.slot}
              </Grid>
              <Grid item xs={12} sm={6}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Estimate Time:{" "}
                </span>{" "}
                {selectedcomplaint?.estimateTimeResolution}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={0.5}>
              <Grid item xs={12} sm={12}>
                <span style={{display:"flex",flexWrap:"wrap"}}>
                <span style={{ color: "#D91029", fontWeight: "bold" }}>
                  Address: &nbsp;
                </span>

                { selectedcomplaint?.address?.mainaddress +
                  " " +
                   
                  selectedcomplaint?.address?.pincode?.city?.name +
                  " " +
                  selectedcomplaint?.address?.pincode?.state?.name+" "+
                  selectedcomplaint?.address?.pincode?.pincode   }
                  </span>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Viewpopup;

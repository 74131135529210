import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import validations from "../schemas/validations";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import StatusInfo from "./statusInfo";
import { createStatus } from "../service/Status";
import { useSelector } from "react-redux";
import swal from "sweetalert";
function getSteps() {
  return ["Status Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <StatusInfo formData={formData} />;
    default:
      return null;
  }
}

function CreateStatus() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.status);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  const submitForm = async (values, actions) => {
    const requestData = {
      name: values.name,
      recstatus: values.recstatus,
      subrecstatus: values.subrecstatus,
      resourcecode: "JAINA",
      status: "ACTIVE",
      syncstatus: "SYNCED",
      type: "STATUS",
      classname: "Category"
    };

    try {
      const response = await dispatch(createStatus(requestData));
      actions.setSubmitting(false);
      actions.resetForm();
      setActiveStep(0);
      if (!response.error && !loading) {
        navigate("/app/StatusList/status-list");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      actions.setSubmitting(false);
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  useEffect(() => {
    if (error) {
      swal({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  }, [error]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Status Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isLoading}
                          >
                            {loading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateStatus;

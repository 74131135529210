import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { FaRegEdit } from "react-icons/fa";
import { deleteAnalysis, getAnalysis } from "./service/Analysis";
import { getCause } from "app/causeMaster/service/Cause";
import AnalysisEdit from "./Analysisedit";

function AnalysisList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { analysis, loading } = useSelector((state) => state.analysis);
  const { cause } = useSelector((state) => state.cause);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  function editBrand(item) {
    setopenpopup(true);
    setSelectedAnalysis(item);
  }

  function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteAnalysis(item))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getCause());
    dispatch(getAnalysis());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DataTable
        canSearch={true}
        table={{
          columns: [
            {
              Header: "cause",
              accessor: "mappingcode",
              Cell: ({ value }) => {
                const causeName = cause?.find(
                  (i) => i?.code == value
                )?.name;
                return causeName || "Unknown Cause";
              },
            },
            { Header: "analysis", accessor: "name" },
            {
              Header: "Created Date",
              accessor: "createddate",
              Cell: ({ value }) => {
                const formattedDate = value
                  ? new Date(value).toDateString()
                  : "N/A";
                return formattedDate;
              },
            },
            {
              Header: "Action",
              accessor: "action",

              Cell: ({ row }) => (
                <div>
                  <Link to={{}}>
                    <FaRegEdit
                      style={iconStyleedit}
                      onClick={() => {
                        editBrand(row.original);
                      }}
                    />
                  </Link>

                  <Link to={{}}>
                    <MdDelete
                      style={iconStyle}
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    />
                  </Link>
                </div>
              ),
            },
          ],
          rows: analysis || [],
        }}
      />
      <AnalysisEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        selectedAnalysis={selectedAnalysis}
        title={"Analysis Edit"}
      />
    </DashboardLayout>
  );
}

export default AnalysisList;

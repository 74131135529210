import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components

// NewUser layout schemas for form and form feilds

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { useNavigate } from "react-router-dom";

import PurchaseDetail from "./components/purchaseDetail";
import PersonalDetail from "./components/personalDetail";
import ContactDetail from "./components/contactDetail";
import AddressDetail from "./components/addressDetail";
import ProductDetail from "./components/productdetail";

import { useDispatch } from "react-redux";
import {
  createproductregistration,
  fileUpload,
  getProductregistration,
} from "./service/prodctRegistration";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import "./Style.css";
import swal from "sweetalert";

function getSteps() {
  return [
    "Product Detail",
    "Purchase Detail",
    "Personal Detail",
    "Contact Detail",
    "Address Detail",
  ];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ProductDetail formData={formData} />;
    case 1:
      return <PurchaseDetail formData={formData} />;
    case 2:
      return <PersonalDetail formData={formData} />;
    case 3:
      return <ContactDetail formData={formData} />;
    case 4:
      return <AddressDetail formData={formData} />;

    default:
      return null;
  }
}

function ProductRegistration(props) {
  const { handleClose } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const { error, loading } = useSelector((state) => state.productregistration);

  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("mmu-auth"))?.profileid
  );
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  // upload file  ==========================
  // const UploadFile = (code, document) => {
  //   const formData = new FormData();
  //   const body = { code: profileId, model: code, resourcecode: "JAINA" };

  //   // Update the formData object
  //   formData.append("file", document);
  //   formData.append("body", JSON.stringify(body));
  //   dispatch(fileUpload(formData));
  // };

  const UploadFile = async (photos) => {
    const body = { code: profileId, resourcecode: "JAINA" };
    let documentIds = [];
    const formData = new FormData();
    formData.append("file", photos);
    formData.append("body", JSON.stringify(body));
    try {
      const response = await dispatch(fileUpload(formData)).unwrap();
      // if (response?.docid) {
      //   documentIds.push(response.docid);
      // } 
      if (response?.id) {
        documentIds.push(response.id);
      } 
      else {
        swal("", "No docId found in response.", "warning");
        console.error("No docId found in response.");
        return;
      }
    } catch (error) {
      swal("File upload failed:", error, "error");
      console.error("File upload failed:", error);
      return;
    }
    return documentIds;
  };

  const submitForm = async (values, actions) => {
    if (values.address === undefined) {
      swal("oops!", "Please Add Address First!", "error");
      actions.setSubmitting(false);
    } else {
      let docIds = [];
      if (values.document) {
        docIds = await UploadFile(values.document);
      }

      values.brand = JSON.parse(values.brand);
      values.modal = JSON.parse(values.modal);
      values.product = JSON.parse(values.product);

      let recStatusValue =
        values.address.recstatus === "APPROVED" &&
        values.recstatus === "APPROVED"
          ? "APPROVED"
          : "PENDING";

      if (values.recstatus === "APPROVED") {
        const requestData = {
          address: values.address,
          alternatephonenumber: values.alternatephonenumber,
          documentofproof: docIds?.join(","),
          dateofpurchase: values.dateofPurchase,
          email: values.email,
          firstname: values.firstname,
          id: null,
          lastname: values.lastname,
          phonenumber: values.mobilenumber,
          description: values.description,
          category: values.categoryname,
          product: {
            description: values.description,
            brand: values.brand,
            serialnumber: values.serialNumber,
            model: values.modal,
            product: values.product,
            recstatus: "APPROVED",
            status: "ACTIVE",
            syncstatus: "SYNCED",
          },
          profileid: profileId,
          purchasefromretailer: values.purchaseofRetailer,
          recstatus: recStatusValue,
          status: "ACTIVE",
          syncstatus: "SYNCED",

          title: values.title,
        };

        //   // Dispatch the createBrand action and wait for the response
        const response = await dispatch(createproductregistration(requestData));

        if (!response.error && !loading) {
          const code = response.payload?.data?.dto?.code;
          // UploadFile(code, values.document);
          handleClose();
          swal(
            "Good job!",
            "Your product has been successfully Register!",
            "success"
          );
          dispatch(getProductregistration(profileId));
          actions.setSubmitting(false);
          actions.resetForm();
          setActiveStep(0);
        } else {
          actions.resetForm();
          setActiveStep(0);
          swal({
            icon: "error",
            title: "Error",
            text: error?.message ? error?.message : "Something went wrong.",
          });
        }
      } else {
        const requestData = {
          address: values.address,
          alternatephonenumber: values.alternatephonenumber,
          documentofproof: docIds?.join(","),
          dateofpurchase: values.dateofPurchase,
          email: values.email,
          firstname: values.firstname,
          id: null,
          lastname: values.lastname,
          phonenumber: values.mobilenumber,
          description: values.description,
          category: values.categoryname,
          product: {
            description: values.description,
            brand: values.brand,
            serialnumber: values.serialNumber,
            model: values.modal,
            product: values.product,
            recstatus: "PENDING",
            status: "ACTIVE",
            syncstatus: "SYNCED",
          },
          profileid: profileId,
          purchasefromretailer: values.purchaseofRetailer,
          recstatus: recStatusValue,
          status: "ACTIVE",
          syncstatus: "SYNCED",

          title: values.title,
        };
        const response = await dispatch(createproductregistration(requestData));

        if (!response.error && !loading) {
          const code = response?.payload?.data?.dto?.code;
          // UploadFile(code, values.document);
          handleClose();
          swal(
            "Good job!",
            "Your product has been successfully Register!",
            "success"
          );
          dispatch(getProductregistration(profileId));
          actions.setSubmitting(false);
          actions.resetForm();
          setActiveStep(0);
        } else {
          actions.resetForm();
          setActiveStep(0);
          swal({
            icon: "error",
            title: "Error",
            text: error?.message ? error?.message : "Something went wrong.",
          });
        }
      }
    }
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <Grid container mt={2}>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <MDBox mx={2} mt={-3}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        <div
                          style={{
                            fontSize: window.innerWidth <= 700 ? "9px" : "12px",
                          }}
                        >
                          {label}
                        </div>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={3}>
                <MDBox>
                  {getStepContent(activeStep, {
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        back
                      </MDButton>
                    )}
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      disabled={isLoading}
                    >
                      {loading ? (
                        <MoonLoader color="#f2fefa" size={16} />
                      ) : isLastStep ? (
                        "submit"
                      ) : (
                        "next"
                      )}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default ProductRegistration;

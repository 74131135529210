import Analytics from "layouts/dashboards/analytics";

import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";

import Otp from "layouts/pages/otp/Otp";
import Createslot from "layouts/pages/slotcreation";
import SlotList from "layouts/pages/slotcreation/SlotList";
import NewAddress from "layouts/pages/AddressInformation/new-address";
import NewPatient from "layouts/pages/patientsignup/new-patient";
import NewDoctor from "layouts/pages/doctorsignup";
import QualificationForm from "layouts/pages/qualification/new-qualification";
import NewCategory from "layouts/pages/category";
//import Steeper from "layouts/pages/horzontal steeper/steeper";
import Steeper from "layouts/pages/horzontal steeper/Steeper";
import NewBookingCalender from "./layouts/pages/Booking Calender/new-bookingCalender";
import NewRegistration from "app/registration/NewRegistration";
import MobileOtpVerification from "app/MobileVerification/MobileOtpverification";
import ForgotPassword from "app/Forgot Password";
import NewMpin from "app/New mpin/NewMpin";
import CreateBrand from "app/createBrand/componets/CreateBrand";
import BrandList from "app/createBrand/componets/BrandList";
import CreateProduct from "app/productMaster/componets/CreateProduct";
import ProductList from "app/productMaster/componets/ProductList";
import ModalMaster from "app/modalMaster/ModalMaster";
import ModalList from "app/modalMaster/ModalList";
import ProductRegistration from "app/productRegistration/ProductRegistration";

import MyProduct from "app/productRegistration/MyProduct";
import ServiceRequest from "app/serviceRequest/ServiceRequest";
import ServerList from "app/serviceRequest/ServerList";
import SymptomMaster from "app/symptomMaster/SymptomMaster";
import SymptomList from "app/symptomMaster/SymptomList";
import CauseMaster from "app/causeMaster/CauseMaster";
import CauseList from "app/causeMaster/CauseList";
import AnalysisMaster from "app/analysisMaster/AnalysisMaster";
import AnalysisList from "app/analysisMaster/AnalysisList";
import StatusList from "app/statusMaster/componets/StatusList";
import CreateStatus from "app/statusMaster/componets/CreateStatus";

const routes = [
  {
    type: "collapse",

    name: localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },

      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic />,
      },
      {
        name: "New Registration",
        key: "new-registration",
        route: "/app/newregistration/registration",
        component: <NewRegistration />,
      },
      {
        name: "MobileOtp Verification",
        key: "mobile-otp",
        route: "/app/MobileOtpverification/mobile-otp",
        component: <MobileOtpVerification />,
      },
      {
        name: "FORGOT PASSWORD",
        key: "forgot-password",
        route: "/app/ForgotPassword/forgot-password",
        component: <ForgotPassword />,
      },
      {
        name: "NEW MPIN",
        key: "new-mpin",
        route: "/app/NewMpin/new-mpin",
        component: <NewMpin />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "New Qualification",
        key: "new-qualification",
        route: "layout/pages/profile/new-qualification",
        component: <QualificationForm />,
      },
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "collapse",
    name: "Brand Master",
    key: "marolemaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Brand",
        key: "create-brand",
        route: "app/CreateBrand/create-brand",
        component: <CreateBrand />,
      },
      {
        name: "Create Brand",
        key: "create-brand",
        route: `app/CreateBrand/create-brand/:id`,
        component: <CreateBrand />,
      },
      {
        name: "Brand List",
        key: "brand-list",
        route: "app/BrandList/brand-list",
        component: <BrandList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Product Master",
    key: "productmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Product",
        key: "create-product",
        route: "app/Createproduct/create-product",
        component: <CreateProduct />,
      },

      {
        name: "Product List",
        key: "product-list",
        route: "app/productlist/product-list",
        component: <ProductList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Model Master",
    key: "modalmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Model",
        key: "create-model",
        route: "app/createmodel/create-model",
        component: <ModalMaster />,
      },

      {
        name: "Model List",
        key: "model-list",
        route: "app/modellist/model-list",
        component: <ModalList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Symptom Master",
    key: "symptommaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Symptom",
        key: "create-symptom",
        route: "app/createsymptom/create-symptom",
        component: <SymptomMaster />,
      },

      {
        name: "Symptom List",
        key: "symptom-list",
        route: "app/symptomlist/symptom-list",
        component: <SymptomList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Cause Master",
    key: "causemaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Cause",
        key: "create-cause",
        route: "app/createcause/create-cause",
        component: <CauseMaster />,
      },

      {
        name: "Cause List",
        key: "cause-list",
        route: "app/causelist/cause-list",
        component: <CauseList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Analysis Master",
    key: "analysismaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Analysis",
        key: "create-analysis",
        route: "app/createanalysis/create-analysis",
        component: <AnalysisMaster />,
      },

      {
        name: "Analysis List",
        key: "analysis-list",
        route: "app/analysislist/analysis-list",
        component: <AnalysisList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Status Master",
    key: "statusmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Status",
        key: "create-status",
        route: "app/createstatus/create-status",
        component: <CreateStatus />,
      },

      {
        name: "Status List",
        key: "status-list",
        route: "app/statuslist/status-list",
        component: <StatusList />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Product Registration",
    key: "productregistration",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Product Registration",
        key: "product-registration",
        route: "app/productregistration/product-registration",
        component: <ProductRegistration />,
      },
      {
        name: "My Product",
        key: "my-product",
        route: "app/productRegistration/my-product",
        component: <MyProduct />,
      },
    ],
  },
  {
    type: "collapse",
    name: "complaint  Request",
    key: "servicerequest",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Request",
        key: "create-request",
        route: "app/serviceRequest/create-request",
        component: <ServiceRequest />,
      },

      {
        name: "My Complaint",
        key: "complaint-list",
        route: "app/serviceRequest/complaint-list",
        component: <ServerList />,
      },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "User Master",
  //   key: "mausermaster",
  //   icon: <Icon fontSize="medium">person</Icon>,
  //   collapse: [

  //     {
  //       name: "Users List",
  //       key: "users-list",
  //       route: "/pages/users/users-list",
  //       component: <UsersListTable />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Investment Menu",
  //   key: "investmentmaster",
  //   icon: <Icon fontSize="medium">person</Icon>,
  //   collapse: [

  //     {
  //       name: "Investment Panel",
  //       key: "investmentpanel",
  //       route: "/pages/users/investment-panel",
  //       component: <Investment/>,
  //     },
  //     {
  //       name: "Investment List",
  //       key: "investmentlist",
  //       route: "/pages/users/investment-list",
  //       component: <InvestmentList/>,
  //     },
  //     {
  //       name: "Investment Redeem",
  //       key: "investmentredeem",
  //       route: "/pages/users/investment-redeem",
  //       component: <InvestmentRedeem/>,
  //     },
  //     {
  //       name: "Withdrawal Approval",
  //       key: "withdrawalapproval",
  //       route: "/pages/users/withdrawal-approval",
  //       component: <WithDrawalapproval/>,
  //     },
  //   ],
  // },
];

export default routes;

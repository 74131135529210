import { createSlice } from "@reduxjs/toolkit";
import { createCause, deleteCause, getCause, updateCause } from "../service/Cause";





const initialState = {
  cause: [],
  loading: false,
  error: null,
};

export const causeDetail = createSlice({
  name: "causeDetail",
  initialState,
  extraReducers: {
    [createCause.pending]: (state) => {
      state.loading = true;
    },
    [createCause.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.cause.push(action.payload.data.dto);
    },
    [createCause.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getCause.pending]: (state) => {
      state.loading = true;
    },
    [getCause.fulfilled]: (state, action) => {
      state.loading = false;
      state.cause = action.payload.data.dtoList;
    },
    [getCause.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteCause.pending]: (state) => {
      state.loading = true;
    },
    [deleteCause.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.cause = state.cause.filter((cause) => cause.id !== id);
      }
    },
    [deleteCause.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCause.pending]: (state) => {
      state.loading = true;
    },
    [updateCause.fulfilled]: (state, action) => {
      state.loading = false;
    
      const index = state.cause.findIndex(cause => cause.id === action.payload.data.dto.id)
      state.cause[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateCause.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default causeDetail.reducer;

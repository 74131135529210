import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { updateSymptom } from "./service/Symptom";

const validationSchema = Yup.object().shape({
  brand: Yup.string().required("Brand is required."),
  product: Yup.string().required("Product is required."),
  symptomname: Yup.string().required("Symptom is required."),
});

const SymptomEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedSymptom, openpopup, setopenpopup } = props;
  const { brand } = useSelector((state) => state.brand);
  const { product } = useSelector((state) => state.product);

  const [defaultValues, setDefaultValues] = useState({
    product: "",
    brand: "",
    symptomname: "",
  });

  const fetchProductOptions = async (selectedBrand) => {
    const result = product?.filter(
      (productItem) => productItem?.parenthierarchicalcode === selectedBrand
    );
    setProductlist(result);
  };

  const handleClose = () => {
    setopenpopup(false);
  };

  useEffect(() => {
    async function getproductdefaultvalue() {
      if (selectedSymptom) {
        const productHericalcode = selectedSymptom?.mappingcode;
        const brandHericalcode = product?.find(
          (p) => p?.name === productHericalcode
        )?.parenthierarchicalcode;

        const defaultProductList = product?.filter(
          (p) => p?.parenthierarchicalcode === brandHericalcode
        );

        setDefaultValues({
          product: productHericalcode,
          brand: brandHericalcode,
          symptomname: selectedSymptom?.name || "",
        });

        setProductlist(defaultProductList);
      }
    }

    getproductdefaultvalue();
  }, [selectedSymptom, product]);

  const [productlist, setProductlist] = useState([]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, action) => {
          const requestData = {
            ...selectedSymptom,
            name: values.symptomname,
            mappingcode: values.product
              ? values.product
              : selectedSymptom?.mappingcode,
          };
          dispatch(updateSymptom(requestData));
          handleClose();
          action.resetForm();
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <DialogContent style={{ maxwidth: 550 }}>
              <MDBox p={3}>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="brand"
                          style={{
                            marginLeft: "-14px",
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Select Brand{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="brand"
                          id="brand"
                          value={values.brand}
                          onChange={(e) => {
                            const selectedBrand = e.target.value;
                            setFieldValue("brand", selectedBrand);
                            fetchProductOptions(selectedBrand);
                          }}
                          error={touched.brand && Boolean(errors.brand)}
                        >
                          <option value=""></option>
                          {brand?.map((option) => (
                            <option
                              key={option.id}
                              value={option.hierarchicalcode}
                            >
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="brand"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="product"
                          style={{
                            marginLeft: "-14px",
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Select Product{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="product"
                          id="product"
                          value={values.product}
                          onChange={(e) => {
                            const selectedProduct = e.target.value;
                            setFieldValue("product", selectedProduct);
                          }}
                          error={touched.product && Boolean(errors.product)}
                        >
                          <option value=""></option>
                          {productlist?.map((option) => (
                            <option
                              key={option.id}
                              value={option.mappingcode}
                            >
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="product"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label=<span>
                            Symptom
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="symptomname"
                          id="symptomname"
                          value={values.symptomname}
                          onChange={(e) => {
                            const symptomnameValue = e.target.value;
                            setFieldValue("symptomname", symptomnameValue);
                          }}
                          variant="standard"
                          error={
                            touched.symptomname && Boolean(errors.symptomname)
                          }
                          helperText={
                            touched.symptomname && errors.symptomname ? (
                              <span style={{ fontWeight: "400" }}>
                                {errors.symptomname}
                              </span>
                            ) : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SymptomEdit;

import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";
// NewUser page components
import FormField from "../FormField";

function PersonalDetail({ formData }) {
  const { formField, values, errors, touched } = formData;
  const [selectedTitle, setSelectedTitle] = useState("");
  const { firstname, lastname } = formField;
  const { firstname: firstnameV, lastname: lastnameV } = values;

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("mmu-auth"));
    if (authData) {
      const { title = "", name = "" } = authData;
      const nameParts = name.split(" ");
      
      values.title = title;
      values.firstname = nameParts[0] || "";
      values.lastname = nameParts.slice(1).join(" ");
    }
  }, []);

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Personal Detail
      </MDTypography>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="gender"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Select Title
              </InputLabel>

              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="title"
                id="title"
                value={values.title}
                onChange={(e) => {
                  const selectedTitle = e.target.value;
                  setSelectedTitle(selectedTitle);

                  // Update the gender value in the formData object
                  formData.values.title = selectedTitle;

                  // Clear the gender error when a valid selection is made
                  formData.errors.title = "";
                }}
                error={!!errors.title}
              >
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
              </Select>

              {errors.title && (
                <FormHelperText error>{errors.title}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "-10px" : "auto" }}
            >
              <FormField
                type={firstname.type}
                label={firstname.label}
                name={firstname.name}
                value={firstnameV}
                placeholder={firstname.placeholder}
                error={errors.firstname && touched.firstname}
                success={firstnameV.length > 0 && !errors.firstname}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            type={lastname.type}
            label={lastname.label}
            name={lastname.name}
            value={lastnameV}
            placeholder={lastname.placeholder}
            error={errors.lastname && touched.lastname}
            success={lastnameV.length > 0 && !errors.lastname}
          />
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for PersonalDetail
PersonalDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PersonalDetail;

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { getStatus, updateStatus } from "../service/Status";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

const Statusedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedStatus, openpopup, setopenpopup } = props;

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    recstatus: Yup.string().required("Status is required."),
    subrecstatus: Yup.string().required("Sub Status is required."),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedStatus?.name || "",
      recstatus: selectedStatus?.recstatus || "",
      subrecstatus: selectedStatus?.subrecstatus || "",
    },
    validationSchema,
    onSubmit: (values) => {
      const updatedStatus = {
        ...selectedStatus,
        name: values.name,
        recstatus: values.recstatus,
        subrecstatus: values.subrecstatus,
      };
      dispatch(updateStatus(updatedStatus));
      dispatch(getStatus());
      setopenpopup(false);
    },
  });

  useEffect(() => {
    formik.setValues({
      name: selectedStatus?.name || "",
      recstatus: selectedStatus?.recstatus || "",
      subrecstatus: selectedStatus?.subrecstatus || "",
    });
  }, [selectedStatus]);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label="Name"
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>

          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label="Status"
              variant="standard"
              fullWidth
              id="recstatus"
              name="recstatus"
              value={formik.values.recstatus}
              onChange={formik.handleChange}
              error={formik.touched.recstatus && Boolean(formik.errors.recstatus)}
              helperText={
                formik.touched.recstatus && formik.errors.recstatus ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.recstatus}
                  </p>
                ) : null
              }
            />
          </MDBox>

          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label="Sub Status"
              variant="standard"
              fullWidth
              id="subrecstatus"
              name="subrecstatus"
              value={formik.values.subrecstatus}
              onChange={formik.handleChange}
              error={formik.touched.subrecstatus && Boolean(formik.errors.subrecstatus)}
              helperText={
                formik.touched.subrecstatus && formik.errors.subrecstatus ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.subrecstatus}
                  </p>
                ) : null
              }
            />
          </MDBox>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Statusedit;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import { updateAnalysis, updateCause } from "./service/Analysis";

const validationSchema = Yup.object().shape({
  cause: Yup.string().required("Cause is required."),
  analysisname: Yup.string().required("Analysis is required."),
});

const AnalysisEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedAnalysis, openpopup, setopenpopup } = props;
  const { cause } = useSelector((state) => state.cause);

  const [defaultValues, setDefaultValues] = useState({
    cause: "",
    analysisname: "",
  });

  const handleClose = () => {
    setopenpopup(false);
  };

  useEffect(() => {
    async function getanalysisdefaultvalue() {
      if (selectedAnalysis) {
        setDefaultValues({
          cause: selectedAnalysis?.mappingcode || "",
          analysisname: selectedAnalysis?.name || "",
        });
      }
    }
    getanalysisdefaultvalue();
  }, [selectedAnalysis]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#D91029",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, action) => {
          const requestData = {
            ...selectedAnalysis,
            name: values.analysisname,
            mappingcode: values.cause
              ? values.cause
              : selectedAnalysis?.mappingcode,
          };
          dispatch(updateAnalysis(requestData));
          handleClose();
          action.resetForm();
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <DialogContent style={{ maxwidth: 550 }}>
              <MDBox p={3}>
                <MDBox>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="cause"
                          style={{
                            marginLeft: "-14px",
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Select Cause{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="cause"
                          id="cause"
                          value={values.cause}
                          onChange={(e) => {
                            const selectedBrand = e.target.value;
                            setFieldValue("cause", selectedBrand);
                          }}
                          error={touched.cause && Boolean(errors.cause)}
                        >
                          <option value=""></option>
                          {cause?.map((option) => (
                            <option key={option.id} value={option.code}>
                              {option.name}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="cause"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label=<span>
                            Analysis
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="analysisname"
                          id="analysisname"
                          value={values.analysisname}
                          onChange={(e) => {
                            const analysisnameValue = e.target.value;
                            setFieldValue("analysisname", analysisnameValue);
                          }}
                          variant="standard"
                          error={touched.analysisname && Boolean(errors.analysisname)}
                          helperText={
                            touched.analysisname && errors.analysisname ? (
                              <span style={{ fontWeight: "400" }}>
                                {errors.analysisname}
                              </span>
                            ) : null
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AnalysisEdit;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";

import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";
import { getDelar } from "app/createdelar/server/delar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

function MoreDetail({ formData }) {
  const dispatch = useDispatch();
  const [remarks, setRemarks] = useState("");
  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context
  const [color] = useState("#344767");
  const [photosError, setPhotosError] = useState(false);

  const { formField, values, errors, touched } = formData;
  const { delar, loading } = useSelector((state) => state.delar);

  console.log(delar);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handlePhotoChange = (e) => {
    if (e.target.files) {
      setPhotosError(false);
      const fileList = Array.from(e.target.files);
      if (photosV?.length + fileList.length > 3) {
        swal("", "You can upload a maximum of 3 images.", "warning");
        return;
      }
      const updatedPhotos = [...photosV, ...fileList];
      setFieldValue("photos", updatedPhotos);
    }
  };

  const handleDeletePhoto = (index) => {
    const updatedPhotos = photosV?.filter((_, i) => i !== index);
    setFieldValue("photos", updatedPhotos);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
    // For example, let's assume you want to ensure that the "brand" field is not empty onBlur.
    // if (!value) {
    //   setFieldError(name, "Brand is required");
    // } else {
    //   setFieldError(name, ""); // Clear the error if it's valid
    // }
  };

  const { specialinstruction, lastcomplaint, issues, photos } = formField;

  const {
    specialinstruction: specialinstructionV,
    Dealer: dealerV,
    purchasesource: purchasesourceV,
    callsource: callsourceV,
    callnature: callnatureV,
    purchasesubsource: purchasesubsourceV,
    callreason: callreasonV,
    invoice: invoiceV,
    complaintpriority: complaintpriorityV,
    lastcomplaint: lastcomplaintV,
    calltype: calltypeV,
    closedreason: closedreasonV,
    wallmount: wallmountV,
    fullreport: fullreportV,
    issues: issuesV,
    photos: photosV,
  } = values;
  useEffect(() => {
    dispatch(getDelar());
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">More Detail</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>

      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.dealer && touched.dealer}>
              <InputLabel
                htmlFor="dealer"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Select Dealer{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="dealer"
                id="dealer"
                value={values.dealer ? values.dealer : dealerV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                {delar.map((option) => (
                  <option key={option.id} value={JSON.stringify(option)}>
                    {option.name}
                  </option>
                ))}
              </Select>
              {errors.dealer && touched.dealer && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.dealer}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "0px" : "auto" }}
            >
              <FormControl
                fullWidth
                error={!!errors.calltype && touched.calltype}
              >
                <InputLabel
                  htmlFor="calltype"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Call Type{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>

                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="calltype"
                  id="calltype"
                  value={calltypeV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>

                  <option value="Demo">Demo</option>
                  <option value="installation">Installation</option>
                  <option value="Service">Service</option>
                  <option value="Stock Repair">Stock Repair</option>
                  <option value="Warehouse Repair">Warehouse Repair</option>
                </Select>
                {errors.calltype && touched.calltype && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.calltype}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>
        </Grid>
        {/* remove this according  demand =========================================*/}
        {/* <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
          <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
            
              <FormControl fullWidth >
                <InputLabel htmlFor="closedreason" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Closed Reason</InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="closedreason"
                  id="closedreason"
                  value={closedreasonV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>
                  
                  <option value="Air Throw">Air Throw</option>
                  <option value="Automatic Shut Down">Automatic Shut Down</option>
                  <option value="Electric Spark">Electric Spark</option>
                
                </Select>
                
              </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
            <div style={{marginTop: window.innerWidth <= 700 ? "0px" : "auto"}}>
            
           <FormControl fullWidth >
             <InputLabel htmlFor="closedreason" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Wall mount</InputLabel>
             <Select
               native
               variant="standard"
               style={{ width: "100%" }}
               name="wallmount"
               id="wallmount"
               value={wallmountV}
               onChange={handleChange}
               onBlur={handleBlur}
             >
               <option value=""></option>
               
               <option value="Yes">Yes</option>
               <option value="No">No</option>
              
             
             </Select>
             
           </FormControl>
           </div>
         </Grid>
            </Grid> */}

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "0px" : "auto" }}
            >
              <FormControl
                fullWidth
                error={!!errors.fullreport && touched.fullreport}
              >
                <InputLabel
                  htmlFor="fullreport"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Fault Report{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="fullreport"
                  id="fullreport"
                  value={fullreportV}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value=""></option>

                  <option value="No Picture">No Picture</option>
                  <option value="No Sound">No Sound</option>
                </Select>
                {errors.fullreport && touched.fullreport && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.fullreport}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "5px" : "auto" }}
            >
              <FormField
                type={specialinstruction.type}
                label={specialinstruction.label}
                name={specialinstruction.name}
                value={specialinstructionV}
                placeholder={specialinstruction.placeholder}
                error={errors.specialinstruction && touched.specialinstruction}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div
              style={{ marginTop: window.innerWidth <= 700 ? "0px" : "auto" }}
            >
              <FormField
                type={issues.type}
                label=<span>
                  {issues.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </span>
                multiline
                name={issues.name}
                value={issuesV}
                placeholder={issues.placeholder}
                error={errors.issues && touched.issues}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <label
              style={{
                display: "block",
                fontSize: "1.25rem",
                fontWeight: "500",
                marginBottom: "0.5rem",
              }}
            >
              Add Photos
            </label>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "16px",
              }}
            >
              {photosV?.map((photo, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "13rem",
                  }}
                >
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Uploaded"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "0.375rem",
                      position: "absolute",
                      top: "0",
                      left: "0",
                    }}
                  />
                  <button
                    onClick={() => handleDeletePhoto(index)}
                    title="Delete Photo"
                    style={{
                      position: "absolute",
                      right: "8px",
                      fontSize: "1.25rem",
                      background: "none",
                      border: "none",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    &times;
                  </button>
                </div>
              ))}
              {photosV?.length < 3 && (
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "13rem",
                    borderRadius: "0.375rem",
                    cursor: "pointer",
                    position: "relative",
                    border: `1px solid black`,
                  }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handlePhotoChange}
                    accept="image/*"
                  />
                  <span
                    style={{
                      fontSize: "1.25rem",
                    }}
                  >
                    +
                  </span>
                </label>
              )}
            </div>
            {photosError && (
              <p
                style={{
                  fontSize: "0.875rem",
                }}
              >
                Photo is required.
              </p>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

MoreDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default MoreDetail;
